<template>
  <page-header-wrapper>
    <div class="a-card">
      <WithdrawBillSearch :total-num="totalNum" :queryParam="queryParam" />
      <div class="table-operator">
        <a-button style="display:none" type="dashed" @click="tableOption"
          >{{ (optionAlertShow && '关闭') || '开启' }} alert
        </a-button>
        <a-dropdown v-if="selectedRowKeys.length > 0" v-action:edit>
          <a-menu slot="overlay">
            <a-menu-item key="1" class="hide" click="handleDeleteSelected">
              <a-icon type="delete" />
              删除
            </a-menu-item>
            <!-- lock | unlock -->
          </a-menu>
          <a-button style="margin-left: 8px">
            批量操作
            <a-icon type="down" />
          </a-button>
        </a-dropdown>
      </div>
      <s-table
        :bordered="true"
        ref="table"
        :alert="options.alert"
        :data="loadData"
        :dataSource="data"
        :row-key="(record, index) => index"
        :pagination="{ showSizeChanger: true, showQuickJumper: true, pageSize: 20, total: 50 }"
        :rowSelection="options.rowSelection"
        :scroll="{ x: true }"
      >
        <a-table-column :title="$t('columnName.remarkNo')" align="center">
          <template slot-scope="text, record, index">
            {{index+1}}
          </template>
        </a-table-column>
        <a-table-column title="操作" align="center">
          <span slot-scope="record">
            <a @click="handleDetail(record)">{{ $t("button.detail") }}</a>
            <a-divider type="vertical"/>
            <a-dropdown v-if="record.billStatus == '待确认' || record.billStatus == '待处理'">
            <a class="ant-dropdown-link"> 更多 <a-icon type="down" /> </a>
            <a-menu slot="overlay">
              <!-- 设为 待处理  -->
              <a-menu-item v-if="record.billStatus == '待确认'">
                <a-popconfirm title="确认设置状态为[待处理]?" @confirm="() => handlePending(record)">
                  <a>{{ $t('button.pending') }}</a>
                </a-popconfirm>
              </a-menu-item>
              <!-- 设为 已确认  -->
              <a-menu-item v-if="record.billStatus == '待处理'">
                <a-popconfirm title="确认设置状态为[已完成]?" @confirm="() => handleDecided(record)">
                  <a>{{ $t('button.decided') }}</a>
                </a-popconfirm>
              </a-menu-item>
              <!-- 设为 已取消  -->
              <a-menu-item v-if="record.billStatus == '待确认' || record.billStatus == '待处理'">
                <a-popconfirm title="确认设置状态为[已取消]?" @confirm="() => handleCanceled(record)">
                  <a>{{ $t('button.canceled') }}</a>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
          </span>
        </a-table-column>
        <!-- 提现单编号 -->
        <a-table-column :title="$t('columnName.withdrawBillNo')" align="left">
          <template slot-scope="record">{{record.withdrawBillNo}}</template>
        </a-table-column>
        <!-- 单据状态 -->
        <a-table-column :title="$t('columnName.billStatus')" align="center">
          <template slot-scope="record">{{record.billStatus}}</template>
        </a-table-column>
        <!-- 申请时间 -->
        <a-table-column :title="$t('columnName.createTime')" align="center">
          <template slot-scope="record">{{formatDateTime(record.createTime)}}</template>
        </a-table-column>
        <!-- 转账单号 -->
        <a-table-column :title="$t('columnName.transferNo')" align="left">
          <template slot-scope="record">{{record.billNo}}</template>
        </a-table-column>
        <!-- 申请用户ID -->
        <a-table-column :title="$t('columnName.memberId')" align="left">
          <template slot-scope="record">{{record.memberId}}</template>
        </a-table-column>
        <!-- 申请用户名称 -->
        <a-table-column :title="$t('columnName.memberName')" align="left">
          <template slot-scope="record">{{record.memberName}}</template>
        </a-table-column>
        <!-- 提现金额 -->
        <a-table-column :title="$t('columnName.withdrawAmount')" align="left">
          <template slot-scope="record">{{formatTypeMoney(record.withdrawAmount, record.currentType, record.moneyMultiple)}}</template>
        </a-table-column>
        <!-- 提现渠道 -->
        <a-table-column :title="$t('columnName.withdrawAccountTypeCode')" align="left">
          <template slot-scope="record">{{record.withdrawAccountType}}</template>
        </a-table-column>
        <!-- 账号名称 -->
        <a-table-column :title="$t('columnName.withdrawAccountName')" align="left">
          <template slot-scope="record">{{record.bankAccountName}}</template>
        </a-table-column>
        <!-- paypal账号 -->
        <a-table-column :title="$t('columnName.paypalAccount')" align="left">
          <template slot-scope="record">{{record.paypalAccount}}</template>
        </a-table-column>
        <!-- 银行账号 -->
        <a-table-column :title="$t('columnName.bankAccountNo')" align="left">
          <template slot-scope="record">{{record.bankAccountNo}}</template>
        </a-table-column>
        <!-- 提款备注 -->
        <a-table-column :title="$t('columnName.withdrawMemo')" align="left">
          <template slot-scope="record">{{record.withdrawMemo}}</template>
        </a-table-column>
        <!-- 更新时间 -->
        <a-table-column :title="$t('columnName.updateTime')" align="center">
          <template slot-scope="record">{{formatDateTime(record.updateTime)}}</template>
        </a-table-column>

        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <div slot="image" slot-scope="text">
          <img :src="text" style="max-width:150px;max-height:100px" />
        </div>
        <div slot="imagelist" slot-scope="text">
          <img v-for="(img, idx) in text" :key="idx" :src="img" style="max-height:100px" />
        </div>
        <div slot="money" slot-scope="text, record">
          {{ formatTypeMoney(text, record.currentType, record.moneyMultiple) }}
        </div>

        <div slot="datetime" slot-scope="text">
          {{ formatDateTime(text) }}
        </div>

        <span slot="action" slot-scope="text, record">
          <a vv-if="$auth('table.service_order.detail')" @click="handleDetail(record)">{{ $t('button.detail') }}</a>
          <a-divider type="vertical" />
          <a-dropdown v-if="record.billStatus == '待确认' || record.billStatus == '待处理'">
            <a class="ant-dropdown-link"> 更多 <a-icon type="down" /> </a>
            <a-menu slot="overlay">
              <!-- 设为 待处理  -->
              <a-menu-item vv-if="$auth('table.withDraw.pending')" v-if="record.billStatus == '待确认'">
                <a-popconfirm title="确认设置状态为[待处理]?" @confirm="() => handlePending(record)">
                  <a>{{ $t('button.pending') }}</a>
                </a-popconfirm>
              </a-menu-item>
              <!-- 设为 已确认  -->
              <a-menu-item vv-if="$auth('table.withDraw.decided')" v-if="record.billStatus == '待处理'">
                <a-popconfirm title="确认设置状态为[已完成]?" @confirm="() => handleDecided(record)">
                  <a>{{ $t('button.decided') }}</a>
                </a-popconfirm>
              </a-menu-item>
              <!-- 设为 已取消  -->
              <a-menu-item
                vv-if="$auth('table.withDraw.canceled')"
                v-if="record.billStatus == '待确认' || record.billStatus == '待处理'"
              >
                <a-popconfirm title="确认设置状态为[已取消]?" @confirm="() => handleCanceled(record)">
                  <a>{{ $t('button.canceled') }}</a>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
      </s-table>

      <a-drawer
        :closable="false"
        :visible="showDetailDrawer"
        placement="right"
        width="80%"
        @close="onDetailDrawerClose"
      >
        <WithDrawBillDetail
          ref="withDrawBillDetail"
          :options="detailOptions"
          :showDetailDrawer="showDetailDrawer"
        ></WithDrawBillDetail>
      </a-drawer>
      <CancelOprate ref="cancelOprate" @handleOk="handleCanceledEditOk" />

      <CommentEditor ref="commentEditor" @handleOk="handleCommentOk" />
    </div>
  </page-header-wrapper>
</template>

<script>
// 演示如何使用 this.$dialog 封装 modal 组件
import { changeStatus, getWithdrawBillCount, searchWithdrawBill } from '@/api/pay/withdrawBill'
import moment from 'moment'
import { STable } from '@/components'

import WithdrawBillSearch from '@/views/pay/WithdrawBillSearch'
import CommentEditor from '@/components/comment/CommentEditor'
import CancelOprate from '@/components/comment/CancelOprate'
import WithDrawBillDetail from '@/views/pay/WithDrawBillDetail'

export default {
  name: 'WithdrawBillList',
  components: {
    STable,
    WithdrawBillSearch,
    CommentEditor,
    CancelOprate,
    WithDrawBillDetail
  },
  data() {
    return {
      // 查询参数
      queryParam: { billStatus: [] },
      detailOptions: {},
      showDetailDrawer: false,
      totalNum: {
        waitDecided: 0,
        waitPending: 0
      },
      data: [],
      status: 'all',
      // 加载数据方法 必须为 Promise 对象
      // loadData: parameter => {
      //   console.log('loadData.parameter', parameter)
      //   return getWithdrawBill(Object.assign(parameter, this.queryParam))
      //       .then(res => {
      //         return res.result
      //       })
      // },
      selectedRowKeys: [],
      selectedRows: [],
      withdrawBillModalShow: false,
      withdrawBillData: {},
      // custom table alert & rowSelection
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created() {
    this.tableOption()
    // getRoleList({t: new Date()})
  },
  mounted() {},
  methods: {
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      return searchWithdrawBill(Object.assign(parameter, this.queryParam)).then(res => {
        console.log('loadData->searchWithdrawBill.parameter', parameter, res)
        getWithdrawBillCount(parameter)
          .then(countRes => {
            console.log('loadData->getWithdrawBillCount.parameter', parameter, res)
            this.totalNum = countRes
          })
          .catch(() => {})
        return res
      })
    },
    tableOption() {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            }
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleDetail(record) {
      this.detailOptions = {
        withdrawBillId: record.withdrawBillId,
        withdrawBillNo: record.withdrawBillNo
      }
      this.showDetailDrawer = true
    },

    handlePending(record) {
      const that = this
      record.billChangeStatus = '待处理'
      // this.$refs['commentEditor'].show(record, '提现单状态变更')
      record.common = ''
      let params = {
        record: record
      }
      that.handleCommentOk(params)
    },

    handleDecided(record) {
      const that = this
      if (record.billNo == null || record.billNo.trim() == '') {
        that.$message.warning('转账单号未录入！')
        return
      }
      record.billChangeStatus = '已完成'
      // this.$refs['commentEditor'].show(record, '提现单状态变更')
      record.common = ''
      let params = {
        record: record
      }
      that.handleCommentOk(params)
    },

    handleCommentOk(params) {
      const that = this
      const record = params.record
      console.log('handleCommentOk', record)
      const reqParam = {}
      reqParam.billStatus = record.billChangeStatus
      reqParam.withdrawBillId = record.withdrawBillId
      reqParam.withdrawBillNo = record.withdrawBillNo
      reqParam.remarkComment = record.comment
      console.log('handleCommentOk', record, reqParam)
      return changeStatus(reqParam)
        .then(res => {
          console.log('changeStatus->changeStatus', reqParam, res)
          // this.$message.success(this.$t('entity.saleOrder.enable.success'))
          that.$refs.table.refresh(true)
          that.$message.success('提交成功')
        })
        .catch(() => {
          that.$refs.table.refresh(true)
          that.$message.error('提交失败')
        })
    },

    handleCanceled(record) {
      const that = this
      record.billChangeStatus = '已取消'
      this.$refs['cancelOprate'].show(record)
    },

    handleCanceledEditOk(params) {
      const record = params.record
      const that = this
      console.log('handleCanceledEditOk', record)
      const reqParam = {}
      reqParam.billStatus = record.billChangeStatus
      reqParam.withdrawBillId = record.withdrawBillId
      reqParam.withdrawBillNo = record.withdrawBillNo
      reqParam.billNo = record.billNo
      reqParam.remarkComment = record.remarkComment
      console.log('handleCanceledEditOk', record, reqParam)
      return changeStatus(reqParam)
        .then(res => {
          console.log('changeStatus->changeStatus', reqParam, res)
          // this.$message.success(this.$t('entity.saleOrder.enable.success'))
          that.$refs.table.refresh(true)
          that.$message.success('提交成功')
        })
        .catch(() => {
          that.$refs.table.refresh(true)
          that.$message.error('提交失败')
        })
    },

    resetSearchForm() {
      this.queryParam = {
        date: moment(new Date())
      }
    },

    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    onDetailDrawerClose() {
      //  this.$refs.table.refresh(true)
      this.showDetailDrawer = false
    }
  }
}
</script>
<style lang="less" scoped>
.ant-avatar-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.list-content-item {
  color: rgba(0, 0, 0, 0.45);
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  margin-left: 40px;

  span {
    line-height: 20px;
  }

  p {
    margin-top: 4px;
    margin-bottom: 0;
    line-height: 22px;
  }
}
</style>
