import request from '@/utils/request'
import qs from 'qs'
const baseUrl = '/api'

export function saveWithdrawBillData (parameter) {
  let url = baseUrl + '/pay/withdrawBill/save'
  if (!parameter.withdrawBillId) {
    url = baseUrl + '/pay/withdrawBill/create'
  }
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function searchWithdrawBill (parameter) {
  return request({
    url: baseUrl + '/pay/withdrawBill/search?' + qs.stringify(parameter, { indices: false }),
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list,
      pageNo: res.data.pageInfo.pageNo ? res.data.pageInfo.pageNo : 1,
      totalCount: res.data.pageInfo.totalCount
    }
  })
}

export function listWithdrawBill (parameter) {
  return request({
    url: baseUrl + '/pay/withdrawBill/list',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list
    }
  })
}

export function approvalWithdrawBill (parameter) {
  return request({
    url: baseUrl + '/pay/withdrawBill/approval',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list
    }
  })
}

export function getWithdrawBillDetail (parameter) {
  return request({
    url: baseUrl + '/pay/withdrawBill/getDetail',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function getWithdrawBillData (parameter) {
  return request({
    url: baseUrl + '/pay/withdrawBill/get',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}
export function enableWithdrawBill (parameter) {
  return request({
    url: baseUrl + '/pay/withdrawBill/enable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function disableWithdrawBill (parameter) {
  return request({
    url: baseUrl + '/pay/withdrawBill/disable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function unlockWithdrawBill (parameter) {
  return request({
    url: baseUrl + '/pay/withdrawBill/enable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function lockWithdrawBill (parameter) {
  return request({
    url: baseUrl + '/pay/withdrawBill/disable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function closeWithdrawBill (parameter) {
  const url = baseUrl + '/pay/withdrawBill/close'
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function completeWithdrawBill (parameter) {
  const url = baseUrl + '/pay/withdrawBill/complete'
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function updateWithdrawBill (parameter) {
  const url = baseUrl + '/pay/withdrawBill/save'
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function updateBillNo (parameter) {
  const url = baseUrl + '/pay/withdrawBill/saveBillNo'
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function denyWithdrawBill (parameter) {
  const url = baseUrl + '/pay/withdrawBill/deny'
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function deleteWithdrawBill (parameter) {
  const url = baseUrl + '/pay/withdrawBill/delete'
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function getWithdrawBillCount (parameter) {
  const url = baseUrl + '/pay/withdrawBill/countWithdrawBill'
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function addRemark (parameter) {
  return request({
    url: baseUrl + '/pay/withdrawBill/addRemark',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function changeStatus (parameter) {
  return request({
    url: baseUrl + '/pay/withdrawBill/changeStatus',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}
