<template>
  <div class="table-page-search-wrapper">
    <a-form layout="inline">
      <a-row :gutter="24">
        <a-col :md="8" :sm="24">
          <a-form-item
            :label="$t('field.memberName')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :required="false"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-input
              v-model="queryParam.memberName"
              v-decorator="[
                'queryParam.memberName',
                {
                  rules: [
                    { required: true, message: '请输入' + this.$t('field.memberName') },
                    { max: 100, message: this.$t('field.memberName') + '长度小于等于100' }
                  ]
                }
              ]"
              :placeholder="'请输入' + this.$t('field.memberName')"
              name="queryParam.memberName"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item
            :label="$t('field.withdrawBillNo')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-input
              v-model="queryParam.withdrawBillNo"
              v-decorator="[
                'queryParam.withdrawBillNo',
                {
                  rules: [
                    { required: true, message: '请输入' + this.$t('field.withdrawBillNo') },
                    { max: 100, message: this.$t('field.withdrawBillNo') + '长度小于等于100' }
                  ]
                }
              ]"
              :placeholder="'请输入' + this.$t('field.withdrawBillNo')"
              name="queryParam.withdrawBillNo"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item
            :label="$t('field.billStatus')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-select
              :allowClear="true"
              v-model="queryParam.billStatus"
              :placeholder="'请选择' + this.$t('field.billStatus')"
              mode="multiple"
            >
              <a-select-option v-for="(item, index) in codeList.billStatusList" :key="index" :value="item.value"
                >{{ item.label }}
              </a-select-option>
            </a-select></a-form-item
          >
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item
            v-if="showAdvancedSearchCondition"
            :label="$t('field.transferNo')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-input
              v-model="queryParam.billNo"
              v-decorator="[
                'queryParam.billNo',
                {
                  rules: [
                    { required: true, message: '请输入' + this.$t('field.transferNo') },
                    { max: 100, message: this.$t('field.billNo') + '长度小于等于100' }
                  ]
                }
              ]"
              :placeholder="'请输入' + this.$t('field.transferNo')"
              name="queryParam.billNo"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item
            v-if="showAdvancedSearchCondition"
            :label="$t('field.withdrawAccountTypeCode')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-select
              :allowClear="true"
              v-model="queryParam.withdrawAccountType"
              :placeholder="'请选择' + this.$t('field.withdrawAccountTypeCode')"
            >
              <a-select-option
                v-for="(item, index) in codeList.withdrawAccountTypeCodeList"
                :key="index"
                :value="item.value"
                >{{ item.label }}
              </a-select-option>
            </a-select></a-form-item
          >
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item
            v-if="showAdvancedSearchCondition"
            :label="$t('field.paypalAccount')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-input
              v-model="queryParam.paypalAccount"
              v-decorator="[
                'queryParam.paypalAccount',
                {
                  rules: [
                    { required: true, message: '请输入' + this.$t('field.paypalAccount') },
                    { max: 100, message: this.$t('field.paypalAccount') + '长度小于等于100' }
                  ]
                }
              ]"
              :placeholder="'请输入' + this.$t('field.paypalAccount')"
              name="queryParam.paypalAccount"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item
            v-if="showAdvancedSearchCondition"
            :label="$t('field.bankAccountNo')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-input
              v-model="queryParam.bankAccountNo"
              v-decorator="[
                'queryParam.bankAccountNo',
                {
                  rules: [
                    { required: true, message: '请输入' + this.$t('field.bankAccountNo') },
                    { max: 100, message: this.$t('field.bankAccountNo') + '长度小于等于100' }
                  ]
                }
              ]"
              :placeholder="'请输入' + this.$t('field.bankAccountNo')"
              name="queryParam.bankAccountNo"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :md="24" :sm="24">
          <span class="table-page-search-submitButtons">
            <a-button
              icon="search"
              style="margin-left: 20px"
              type="primary"
              @click="$parent.$parent.$refs.table.refresh(true)"
              >{{ $t('button.search') }}</a-button
            ><a-divider type="vertical" />
            <a-button
              style="margin-left: 8px"
              @click="
                () => {
                  $parent.$parent.queryParam = {}
                  $nextTick(() => {
                    $parent.$parent.$refs.table.refresh(true)
                  })
                }
              "
              >{{ $t('button.reset') }}</a-button
            >
            <a style="margin-left: 8px" @click="toggleAdvancedSearch">
              {{ showAdvancedSearchCondition ? $t('button.collapseIn') : $t('button.collapseOut') }}
              <a-icon :type="showAdvancedSearchCondition ? 'up' : 'down'" />
            </a>
          </span>
        </a-col>
      </a-row>

      <a-divider />
      <a-row>
        <a-col :md="24" :sm="24">
          <span class="table-page-search-submitButtons">
            <a-button size="large" style="margin-left: 20px" @click="qryByOrderStatus('待确认')"
              >待确认({{ totalNum.waitDecided }})</a-button
            >
            <a-divider type="vertical" />
            <a-button size="large" style="margin-left: 20px" @click="qryByOrderStatus('待处理')"
              >待处理({{ totalNum.waitPending }})</a-button
            >
            <a-divider type="vertical" />
            <a-button size="large" style="margin-left: 20px" @click="qryByOrderStatus('已完成')">已完成</a-button>
            <a-divider type="vertical" />
            <a-button size="large" style="margin-left: 20px" @click="qryByOrderStatus('已取消')">已取消</a-button>
            <a-divider type="vertical" />
          </span>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  name: 'WithdrawBillSearch',
  components: {},
  props: {
    // 查询参数
    queryParam: {
      type: Object
    },
    totalNum: {
      type: Object
    }
  },
  data() {
    return {
      showAdvancedSearchCondition: false,
      codeList: {
        billStatusList: [
          { label: '待确认', value: '待确认' },
          { label: '待处理', value: '待处理' },
          { label: '已完成', value: '已完成' },
          { label: '已取消', value: '已取消' }
        ],
        withdrawAccountTypeCodeList: [
          { label: 'PayPal', value: 'PayPal' },
          { label: '银行卡', value: '银行卡' }
        ]
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    toggleAdvancedSearch() {
      this.showAdvancedSearchCondition = !this.showAdvancedSearchCondition
    },
    resetSearchForm() {
      this.queryParam = {
        date: moment(new Date())
      }
    },
    qryByOrderStatus(param) {
      const billStatus = []
      billStatus.push(param)
      this.queryParam.billStatus = billStatus
      this.$parent.$parent.$refs.table.refresh(true)
    }
  }
}
</script>
