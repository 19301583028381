<template>
  <!-- 取消原因 -->
  <a-modal
    centered
    :title="$t('page.cancelOprate')"
    v-model="visible"
    @ok="handleOk"
    @cancel="handleCancel"
    :maskClosable="false"
  >
    <a-textarea :placeholder="$t('page.cancelOprate')" :rows="4" :maxLength="300" v-model="remarkComment"/>
  </a-modal>
</template>

<script>
export default {
  props: {
    record: {
      type: Object
    }
  },
  data () {
    return {
      visible: false,
      remarkComment: ''
    }
  },
  methods: {
    show (record) {
      this.remarkComment = ''
      this.record1 = record
      this.visible = true
      console.log('cancelOprateShow', this.record1)
    },
    handleOk () {
      console.log('click handleOk')
      if (this.remarkComment.trim() === '') {
        this.$message.warning('请输入取消原因！')
        return
      }
      this.record1.remarkComment = this.remarkComment
      this.visible = false
      this.$emit('handleOk', { type: this.type, record: this.record1 })
    },
    handleCancel () {
      this.visible = false
      console.log('click handleCancel')
      this.$emit('cancel')
    },
    onForgeStepCode () {

    }
  }
}
</script>
<style lang="less" scoped>
.step-form-wrapper {
  margin: 0 auto;
  width: 80%;
  max-width: 400px;
}
</style>
