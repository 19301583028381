<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <a-descriptions bordered title="">
        <a-descriptions-item label="提现申请单号">{{ data.withdrawBillNo }}</a-descriptions-item>
        <a-descriptions-item label="提交时间">{{ formatDateTime(data.createTime) }}</a-descriptions-item>
        <a-descriptions-item label="当前状态">{{ data.billStatus }}</a-descriptions-item>
      </a-descriptions>
      <br />

      <a-divider style="margin-bottom: 32px" />
      <a-steps
        style="width:80%;margin-left:10%"
        v-if="data.totalStep == 3"
        :current="data.step"
        :status="data.stepStatus"
      >
        <a-step>
          <span slot="title">待确认</span>
          <span slot="description">{{ formatDateTime(data.midStatusOprTime) }} {{ data.midStatusOprName }}</span>
        </a-step>
        <a-step>
          <span slot="title">待处理</span>
          <span slot="description">{{ formatDateTime(data.finalStatusOprTime) }} {{ data.finalStatusOprName }}</span>
        </a-step>
        <a-step>
          <span slot="title">{{ data.finalStatusCompleted ? data.billStatus : '已完成/已取消 ' }}</span>
          <span slot="description">{{ data.statusRemark }}</span>
        </a-step>
      </a-steps>
      <a-steps
        style="width:80%;margin-left:10%"
        v-if="data.totalStep == 2"
        :current="data.step"
        :status="data.stepStatus"
      >
        <a-step>
          <span slot="title">待确认</span>
          <span slot="description">{{ formatDateTime(data.finalStatusOprTime) }} {{ data.finalStatusOprName }}</span>
        </a-step>
        <a-step>
          <span slot="title">{{ data.billStatus }}</span>
          <span slot="description">{{ data.statusRemark }}</span>
        </a-step>
      </a-steps>
      <br /><br />

      <a-descriptions bordered title="会员信息">
        <a-descriptions-item label="发起用户">{{ data.memberName }}</a-descriptions-item>
        <a-descriptions-item label="用户ID">{{ data.memberId }}</a-descriptions-item>
      </a-descriptions>
      <br />

      <a-descriptions bordered title="提现信息">
        <a-descriptions-item label="提现金额">{{
          formatTypeMoney(data.withdrawAmount, data.currentType, data.moneyMultiple)
        }}</a-descriptions-item>
        <a-descriptions-item label="提现方式">{{ data.withdrawAccountType }}</a-descriptions-item>
        <a-descriptions-item label="提现账号名称">{{ data.bankAccountName }}</a-descriptions-item>
        <a-descriptions-item label="PayPal账号">{{ data.paypalAccount }}</a-descriptions-item>
        <a-descriptions-item label="银行名称">{{ data.bankName }}</a-descriptions-item>
        <a-descriptions-item label="银行卡账号">{{ data.bankAccountNo }}</a-descriptions-item>
        <a-descriptions-item label="提现备注">{{ data.withdrawMemo }}</a-descriptions-item>
      </a-descriptions>
      <br />

      <a-descriptions bordered title="转账信息">
        <a-descriptions-item label="转账单号">{{ data.billNo === null ? '-' : data.billNo }}</a-descriptions-item>
        <a-descriptions-item label="录入时间"
          >{{ data.withdrawTime === 0 ? '-' : formatDateTime(data.withdrawTime) }}
        </a-descriptions-item>
      </a-descriptions>

      <br />
      <a-button
        v-if="data.billStatus == '待处理' && !data.billNo"
        htmlType="button"
        type="primary"
        @click="handleCreateTransfund(data)"
        >录入转账单号
      </a-button>
      <br />
      <a-modal
        :visible="billNoImportShow"
        title="输入转账流水单号"
        width="40%"
        @cancel="handleBillNoImportCancel"
        @ok="handleBillNoImportOk"
      >
        <template>
          <div>
            <a-form-item
              :label="$t('field.transferNo')"
              :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
            >
              <a-input
                v-model="transBillNo"
                :maxLength="64"
                v-decorator="[
                  'queryParam.paypalAccount',
                  {
                    rules: [
                      { required: true, message: '请输入' + this.$t('field.transferNo') },
                      { max: 64, message: this.$t('field.transferNo') + '长度小于等于64' }
                    ]
                  }
                ]"
                :placeholder="'请输入' + this.$t('field.transferNo')"
                name="transBillNo"
              />
            </a-form-item>
          </div>
        </template>
      </a-modal>

      <a-divider style="margin-bottom: 32px" />
      <div v-if="data" class="title">备注信息</div>
      <a-table
        v-if="data && data.remarkList"
        :columns="remarkListColumns"
        :dataSource="data.remarkList"
        :bordered="true"
        :row-key="(record, index) => index"
        :pagination="false"
      >
        <div slot="datetime" slot-scope="text">
          {{ formatDateTime(text) }}
        </div>
        <div class="word-break word-wrap" slot="message" slot-scope="text">
          {{ formatDateTime(text) }}
        </div>
      </a-table>
      <br />

      <a-form-item>
        <a-textarea
          style="width:60%;height:120px"
          :placeholder="'请输入备注信息'"
          :maxLength="300"
          v-model="remarkComment"
        />
        <br />
        <a-popconfirm title="确认发表备注信息?" @confirm="() => handleAddRemark()">
          <a-button htmlType="button" type="primary">发表备注</a-button>
        </a-popconfirm>
      </a-form-item>
      <a-divider style="margin-bottom: 32px" />
      <div style="text-align: center">
        <a-popconfirm title="确认设置状态为[待处理]?" @confirm="() => handlePending(data)">
          <a-button v-if="data.billStatus == '待确认'" style="margin-left: 20px" htmlType="button" type="primary"
            >设置为【待处理】</a-button
          >
        </a-popconfirm>
        <a-popconfirm title="确认设置状态为[已取消]?" @confirm="() => handleCanceled(data)">
          <a-button
            v-if="data.billStatus == '待确认' || data.billStatus == '待处理'"
            style="margin-left: 20px"
            htmlType="button"
            type="primary"
            >设置为【已取消】</a-button
          >
        </a-popconfirm>
        <a-popconfirm title="确认设置状态为[已完成]?" @confirm="() => handleDecided(data)">
          <a-button v-if="data.billStatus == '待处理'" style="margin-left: 20px" htmlType="button" type="primary"
            >设置为【已完成】</a-button
          >
        </a-popconfirm>
      </div>
    </a-card>
    <CancelOprate ref="cancelOprate" @handleOk="handleCanceledEditOk" />
    <CommentEditor ref="commentEditor" @handleOk="handleCommentOk" />
  </page-header-wrapper>
</template>

<script>
import { STable } from '@/components'
import { addRemark, changeStatus, getWithdrawBillDetail, updateBillNo } from '@/api/pay/withdrawBill'
import CommentEditor from '@/components/comment/CommentEditor'
import CancelOprate from '@/components/comment/CancelOprate'

export default {
  name: 'ServiceOrderDetail',
  components: {
    STable,
    CommentEditor,
    CancelOprate
  },
  props: {
    options: {
      type: Object
    },
    showDetailDrawer: {
      type: Boolean
    }
  },
  created() {
    // getRoleList({t: new Date()})
  },
  watch: {
    showDetailDrawer(val, oldVal) {
      console.log(val)
      if (val) {
        this.loadData({})
      }
    }
  },
  mounted() {
    this.loadData({})
  },
  data() {
    return {
      reqParam: {},
      data: {},
      remarkComment: '',
      billNoImportShow: false,
      transBillNo: '',
      remarkListColumns: [
        {
          defaultTitle: '序号',
          title: this.$t('columnName.remarkNo'),
          dataIndex: 'no',
          key: 'no',
          align: 'left',
          defaultSortOrder: 'ascend',
          sorter: (a, b) => {
            console.log(a, b)
            return a.remarkNo > b.remarkNo ? 1 : -1
          }
        },
        {
          defaultTitle: '备注时间',
          title: this.$t('columnName.remarkTime'),
          dataIndex: 'remarkTime',
          key: 'remarkTime',
          align: 'left',
          scopedSlots: {
            customRender: 'datetime'
          }
        },
        {
          defaultTitle: '备注人',
          title: this.$t('columnName.remarkName'),
          dataIndex: 'userInfo.name',
          key: 'userInfo.name',
          align: 'left'
        },
        {
          defaultTitle: '备注内容',
          title: this.$t('columnName.message'),
          dataIndex: 'content',
          key: 'content',
          align: 'left',
          width: '50%',
          scopedSlots: {
            customRender: 'message'
          }
        }
      ]
    }
  },
  methods: {
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      getWithdrawBillDetail(
        Object.assign(parameter, {
          withdrawBillId: this.options.withdrawBillId,
          withdrawBillNo: this.options.withdrawBillNo
        })
      ).then(res => {
        console.log('loadData->getWithdrawBillDetail.parameter', parameter, res)
        res.totalStep = 2
        if (res.billStatus === '已取消' || res.billStatus === '已完成') {
          res.step = 2
          res.stepStatus = 'finish'
        } else {
          res.totalStep = 3
          res.stepStatus = 'process'
          if (res.billStatus === '待处理') {
            res.step = 1
          } else {
            res.step = 0
          }
        }
        // 循环状态变更记录
        res.statusChangeInfoList.forEach(element => {
          // console.log("statusChangeInfoList--element",element)
          if (element.billStatus === '待处理') {
            res.totalStep = 3
            res.midStatusOprName = element.creatorName
            res.midStatusOprTime = element.createTime
          }
          if (res.billStatus === '已取消' || res.billStatus === '已完成') {
            res.finalStatusOprName = element.creatorName
            res.finalStatusOprTime = element.createTime
            res.statusRemark = element.remark
          }
        })

        this.data = res // this.data = res.data
        return this.data
      })
    },

    handleDetail(record) {
      console.log('detail record', record)
      this.options.withdrawBillId = record.withdrawBillId
      this.options.withdrawBillNo = record.withdrawBillNo
      this.loadData({})
    },

    handlePending(record) {
      const that = this
      record.withdrawChangeStatus = '待处理'
      //this.$refs['commentEditor'].show(record, '提现单状态变更')
      record.common = ''
      let params = {
        record: record
      }
      that.handleCommentOk(params)
    },

    handleDecided(record) {
      const that = this

      if (record.billNo == null || record.billNo.trim() == '') {
        that.$message.warning('转账单号未录入！')
        return
      }

      record.withdrawChangeStatus = '已完成'
      //this.$refs['commentEditor'].show(record, '提现单状态变更')
      record.common = ''
      let params = {
        record: record
      }
      that.handleCommentOk(params)
    },

    handleCommentOk(params) {
      const that = this
      const record = params.record
      console.log('handleCommentOk', record)
      const reqParam = {}
      reqParam.billStatus = record.withdrawChangeStatus
      reqParam.withdrawBillId = record.withdrawBillId
      reqParam.withdrawBillNo = record.withdrawBillNo
      reqParam.remarkComment = record.comment
      console.log('handleCommentOk', record, reqParam)
      return changeStatus(reqParam)
        .then(res => {
          console.log('changeStatus->changeStatus', reqParam, res)
          // this.$message.success(this.$t('entity.saleOrder.enable.success'))
          that.options.withdrawBillId = record.withdrawBillId
          that.loadData({})
          that.$message.success('提交成功')
        })
        .catch(() => {
          that.$message.error('提交失败')
        })
    },

    handleCanceled(record) {
      const that = this
      record.withdrawChangeStatus = '已取消'
      record.withdrawChangeStatusDesc = '已取消'
      this.$refs['cancelOprate'].show(record)
    },

    handleCanceledEditOk(params) {
      const record = params.record
      const that = this
      console.log('handleCanceledEditOk', record)
      const reqParam = {}
      reqParam.billStatus = record.withdrawChangeStatus
      reqParam.withdrawBillId = record.withdrawBillId
      reqParam.withdrawBillNo = record.withdrawBillNo
      reqParam.remarkComment = record.remarkComment
      console.log('handleCanceledEditOk', record, reqParam)
      return changeStatus(reqParam)
        .then(res => {
          console.log('changeStatus->changeStatus', reqParam, res)
          that.loadData({})
          that.$message.success('提交成功')
        })
        .catch(() => {
          that.$refs.table.refresh(true)
          that.$message.error('提交失败')
        })
    },

    handleAddRemark() {
      const that = this
      if (this.remarkComment.trim() === '') {
        this.$message.warning('请输入备注信息！')
        return
      }
      this.reqParam.remarkComment = this.remarkComment
      this.reqParam.withdrawBillId = this.data.withdrawBillId
      this.reqParam.withdrawBillNo = this.data.withdrawBillNo
      console.log('detail reqParam', this.reqParam)
      return addRemark(this.reqParam)
        .then(res => {
          console.log('addRemark->addRemark', this.reqParam, res)
          that.loadData({})
          that.$message.success('提交成功')
          that.remarkComment = ''
          return res
        })
        .catch(() => {
          that.$message.error('提交失败')
        })
    },

    handleCreateTransfund(record) {
      console.log('handleCreateTransfund', record)
      this.billNoImportShow = true
      this.transBillNo = ''
    },

    handleBillNoImportOk() {
      const that = this
      if (this.transBillNo.trim() === '') {
        this.$message.warning('请输入转账单号')
        return
      }
      const param = {
        billNo: this.transBillNo,
        withdrawBillId: this.options.withdrawBillId,
        withdrawTime: new Date().getTime()
      }
      console.log('handleBillNoImportOk->param', param)

      this.$confirm({
        title: '确认录入当前转账流水信息',
        content: '转账流水单号[' + param.billNo + ']',
        onOk() {
          console.log('OK')
          updateBillNo(param)
            .then(res => {
              console.log('handleBillNoImportOk->updateWithdrawBill', param, res)
              if (res.success) {
                that.billNoImportShow = false
                that.loadData({})
                that.$message.success('提交成功')
              } else {
                that.$message.error('提交失败,原因：' + res.respMsg)
              }
            })
            .catch(() => {
              that.$message.error('提交失败')
            })
        },
        onCancel() {
          console.log('Cancel')
        },
        class: 'test'
      })
    },

    handleBillNoImportCancel() {
      this.billNoImportShow = false
    }
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        processing: '进行中',
        success: '完成',
        failed: '失败'
      }
      return statusMap[status]
    }
  },
  computed: {
    title() {
      return this.$route.meta.title
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}
</style>
